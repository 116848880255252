.textBox{
    background: #FFFFFF !important;
    border: 1.5px solid #DEE2E6 !important;
    box-shadow: 2px 19px 21px -8px #E6E6E6 !important;
    border-radius: 12px  !important;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 88%;
    transform: translate(-50%, -50%);
    padding: 15px;
}
.textBox > :last-child{
    border-radius: inherit !important;
}

.title{
    font-family: 'Sukhumvit Set';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    /* identical to box height */


    /* Secondary/Dark Color */

    color: #54595E;

    text-align: center;
}

.description{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    text-align: center;

    color: rgba(84, 89, 94, 0.6);
}

.button{
    text-align: center;
}

.closeButton{

    position: absolute;
    right: 10px;
    top: 10px;

    cursor: pointer;
}