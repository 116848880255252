.header{
    font-family: Kanit;
    font-size: 23px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;


    color: #515151;

    margin-bottom: 40px;
    margin-top: 0px;


}


.subheader{
    font-family: Kanit;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

    color: #515151;

}

.text{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #515151;

}

@media( 600px < width < 900px){
    .header{
        font-size: 22px;
        line-height: 33px;
    }
    .subheader{
        font-size:18px;
        line-height: 26px
    }
    .text{
        font-size: 14px;
        line-height:22px;
    }
}

@media(width < 600px){
    .header{
        font-size: 20px;
        line-height: 30px;
    }
    .subheader{
        font-size:16px;
        line-height: 24px
    }
    .text{
        font-size: 10px;
        line-height:16px;
    }
}