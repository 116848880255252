.partner{
    height: 60px !important;
    width: auto !important;
    vertical-align: middle;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
}
.DASTA{
    height: auto !important;
    width: 90px !important;
    vertical-align: middle;
    margin-left: 30px;
    margin-right: 30px;
}
.NSO{
    height: 60px !important;
    width: auto !important;
    vertical-align: middle;
    margin-left: 30px;
    margin-right: 30px;
}
.CAAT{
    height: auto !important;
    width: 126px !important;
    vertical-align: middle;
    margin-left: 30px;
    margin-right: 30px;
}

.AOT{
    height: auto !important;
    vertical-align: middle;
    width: 139px !important;
    margin-left: 30px;
    margin-right: 30px;
}

.SAC{
    height: auto !important;
    vertical-align: middle;
    width: 73px !important;
    margin-left: 30px;
    margin-right: 30px;
}

.imageContainer{
    width: 100% !important;
}

.partnerWrapper{
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #F1F7FD;
}

.text_header{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(1rem, 8vw, 2.65rem);
    line-height: 72px;
    text-align: center;

    color: #194B7E;
}
.mCult{
    height: 80px !important;
    width: auto !important;
    vertical-align: middle;
    margin-left: 30px;
    margin-right: 30px;
}

@media (width < 600px) {
    .partner {
        scale: 0.67;
        margin: 20px 15px;
    }

    .CAAT{
        width: 84px !important;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .AOT{
        width: 93.7px !important;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .SAC{
        width: 48.7px !important;
        margin-left: 20px;
        margin-right: 20px;
    }
    .mCult{
        height: 54px !important;
        margin-left: 20px;
        margin-right: 20px;
    }

    .DASTA{
        width: 60px !important;
        margin-left: 20px;
        margin-right: 20px;
    }
    .NSO{
        height: 40px !important;
        margin-left: 20px;
        margin-right: 20px;
    }
}