.title{
    font-family: Kanit;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #194B7E;
    margin: 10px 0;
    position: relative;
    padding-right: 40px;
}
.tag{

    border-radius: 50px;
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-right: 10px;
    padding: 0 16px;
    white-space: nowrap;
}
.tag:last-child{
    margin-right: 0;
}
.ahref{
    font-family: Kanit;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;
    text-align: left;
    text-decoration: underline;
    color: #0182FA;
}


.shortDescription{
    font-family: Kanit;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #194B7E;
    margin-bottom: 10px;
    margin-top: 10px;

}
