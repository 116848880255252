
@media (width >= 1200px) {
    .frame{
        width: 146.22px;
        height: 144.08px;
        overflow: hidden;
        border-radius: 20px;
        display: inline-block;
        margin-left: 4px;
        margin-right: 4px;
    }
  }
@media (992px <= width < 1200px) {
    .frame{
        width: 120px;
        height: 120px;
        overflow: hidden;
        border-radius: 16px;
        display: inline-block;
        margin-left: 4px;
        margin-right: 4px;
    }
}
@media (768px <= width < 992px) {
    .frame{
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 13px;
        display: inline-block;
        margin-left: 4px;
        margin-right: 4px;
    }

}

.textHeader{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    /* identical to box height */

    text-align: left;

    color: #194B7E;

    margin-bottom: 30px;

}

.text{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 15px;
    line-height: 22px;
    text-align: left;

    color: #000000;

}

.wrapper{
    margin-bottom: 130px;
    margin-top: 100px;
}

.wrapperLeft{
    margin-top: 100px;
}

.sign{
    object-fit: contain;
    width: 70px;
    height: auto;
    margin-right: 30px;
}