.wrapper{
    background-color: #2d455f;
    padding-top: 50px;
    padding-bottom: 50px;
}

.header{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 72px;
    text-align: center;

    color: #FFFFFF;

}

.textbox{
    margin: 0 8%;
    background-color: white;
    padding: 50px;
}

.comment_text{
    font-family: Kanit;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;

    color: #343434;
    vertical-align: middle;
    align-self: center;
}

.commentator_name{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;

    color: #194B7E;
    margin-top: 30px;
    margin-bottom: 0;
}

.commentator_img{
    width: 150px; 
    height: 150px;
    object-fit: contain;
}

.commentator_position{
    font-family: Kanit;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #194B7E;
}