@media (width >= 1280px) {
    .frame{
        width: 146.22px;
        height: 144.08px;
        overflow: hidden;
        border-radius: 20px;
        display: inline-block;
        margin-left: 4px;
        margin-right: 4px;
    }
  }

@media (900px <= width < 1280px) {
    .frame{
        width: 130px;
        height: 130px;
        overflow: hidden;
        border-radius: 18px;
        display: inline-block;
        margin-left: 4px;
        margin-right: 4px;
    }
  }

@media (740px <= width <= 900px) {
    .frame{
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 13px;
        display: inline-block;
        margin-left: 4px;
        margin-right: 4px;
    }

}

.wrapperLeft{
    display:block;
    align-self: center;
    margin-top: 0 !important;
}

@media (width <= 740px){
    .wrapperLeft{
        display: none !important;
    }
}



.wrapper{
    margin-bottom: 130px;
    margin-top: 100px;
}


.image{
    width:100%;
    height: 100%;
}