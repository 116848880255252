.title{
    font-family: Kanit;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #194B7E;
    margin-top: 35px;
}
.tag{
    border-radius: 50px;
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-right: 10px;
    padding: 0 16px;
    white-space: nowrap;
}
.tag:last-child{
    margin-right: 0;
}

@media (width >= 1220px) {
    .dashboardBox{
        height: 633.938px;
    }
  }
@media (900px <= width < 1220px) {
    .dashboardBox{
        height: 52vw;
    }
}
@media (600px <= width < 900px) {
    .dashboardBox{
        height: 49.8vw;
    }
}
@media (width < 600px) {
    .dashboardBox{
        height: 46.6vw;
    }
}

.line_logo{
    object-fit: contain;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    left:0;
}

.line_wrapper{
    white-space: nowrap;

}

.detail{
    font-family: Kanit;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;

    color: #194B7E;

    margin-bottom: 20px;
}
.header{
    font-family: Kanit;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color:#000000;
}
.text{
    font-family: Kanit;
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}