.title{
    margin-top: 3px;
    margin-right: 20px;
    padding: 10px;
}
.titleWrapper{
    position: relative;
    padding-right: 40px;
}
.tag{

    border-radius: 50px !important;
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-right: 10px;
    padding: 0 16px;
    white-space: nowrap;
    width: 100px;
    height:24px !important;
    margin-top: 5px
}
.tag:last-child{
    margin-right: 0;
}
.author{
    font-family: Kanit;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-align: left;    
    color: #0000004D;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 200px
}
.shortDescription{
    font-family: Kanit;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #194B7E;
    margin-bottom: 10px;

}
.bookmark{
    position: absolute; 
    right: 25px; 
    top: 25px;
    width: 40px;
    height: 40px !important;
}

