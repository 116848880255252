.not-found-text {
    padding-top: 60px;
    padding-bottom: 45px;
    font-family: Kanit;
    font-size: 30px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    color: #515151;
}