.PST-header{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
   
    /* or 64px */

    text-align: left;

    color: #515151;

    margin-bottom: 75px;
    margin-top: 0px;



}

.PST-subheader{
    font-family: Kanit;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
 
    /* or 37px */

    text-align: left;

    color: #515151;

}

.PST-text{
    font-family: Kanit;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    text-align: left;

    color: #515151;

}

.ui.secondary.vertical.pointing.menu{
    border-right-width: 3px !important;
}
.ui.secondary.vertical.pointing.menu .item{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */


    text-align: right;

    color: #343434 !important;
}

.ui.secondary.vertical.pointing.menu .item:hover{
    border-color: #3397E8 !important;
    border-right-width: 5px;
    color: #3397E8 !important;
}



.ui.secondary.pointing.menu{
    border-right-width: 3px !important;
}
.ui.secondary.pointing.menu .item{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    /* identical to box height */


    text-align: right;

    color: #343434 !important;
}

.ui.secondary.pointing.menu .item:hover{
    border-color: #3397E8 !important;
    border-right-width: 5px;
    color: #3397E8 !important;
}



.ui.secondary.pointing.menu .active.item{
    border-color: #3397E8 !important;
    border-right-width: 5px !important;
    color: #3397E8 !important; 
}

.ui.segment{
    border: 0px !important;
    box-shadow: none !important;
}