.background{
    position: fixed;
    background-color: #194B7E;
    color: white;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 15px;
    font-size: 0.75rem;
}

.btn {
    height: 46px !important;
    min-height: 46px !important;
    max-height: 46px !important;

}