.textBox{
    background: #FFFFFF !important;
    border: 1.5px solid #DEE2E6 !important;
    box-shadow: 2px 19px 21px -8px #E6E6E6 !important;
    border-radius: 12px  !important;

    width: fit-content !important;
    padding: 20px 50px;
}
.textBox > :last-child{
    border-radius: inherit !important;
}

.title{
    font-family: 'Sukhumvit Set';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    /* identical to box height */


    /* Secondary/Dark Color */

    color: #54595E;

    text-align: center;
}

.description{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    color: #54595E;

}

.button{
    text-align: center;
}

.closeButton{

    position: absolute;
    right: 10px;
    top: 10px;

    cursor: pointer;
}

.blurring.dimmable>.dimmer{
    background-color: rgba(250, 250, 252, .6) !important;
}

.sign{
    object-fit: contain;
    width: 70px;
    height: auto;
    text-align: center;
}