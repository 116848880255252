.closeButton{

    position: absolute;
    right: 10px;
    top: 10px;

    cursor: pointer;
}

.bodyText{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

}


.text{
    color: var(--dark-dark-1, #3A3A3C);
    font-family: Kanit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    
}

.textBold{
    color: var(--dark-dark-1, #3A3A3C);
    text-align: center;
    font-family: Kanit;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 

    margin-top: 3.75rem;
}