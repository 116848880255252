.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1;

}

.popup_inner{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 1164px;
    background-color: #F1F7FD;
}

.close_btn{
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 50px;
    cursor: pointer;
}

.close_btn_white{
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 50px;
    cursor: pointer;
    color: #FFFFFF;
}


.text{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;

    color: #092C50;

    margin-top: 20px;
    margin-bottom: 20px;

}

.input{
    width: 350px;
    margin-bottom: 30px;
    left:0;
    right: 0;
    margin-left: auto !important;
    margin-right: auto !important;

    font-family: Kanit !important;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
}

.form{
    align-items: center;
}

.thankyoubg{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 1164px;
    background-color: #092C50;
}

.tytext1{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
    text-align: center;

    color: #FFFFFF;

    margin-top: 30px;
    margin-bottom: 10px;
}

.tytext2{
    font-family: Kanit;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 48px;
    text-align: center;

    color: #FFFFFF;
}