.buttonWrapper{
    position: relative;
    display: inline-block;
}
.buttonWrapper::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100%);
    height: calc(100%);
    border: 2px solid transparent;
    border-radius: 10em;
    pointer-events: none;
    transition: border-color 0.3s;
  }
  
  .buttonWrapper:focus-within::before {
    border-color: #67B4FC;
  }