.text_header{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 30px;
    text-align: center;

    color: #194B7E;
}

.text{
    font-family: Kanit;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    text-align: center;

    color: #343434;
}

.wrapper{
    padding-top: 50px;
    padding-bottom: 50px;
}



