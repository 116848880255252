.gredientBackground {
    background: linear-gradient(180deg, rgba(241,248,255,1) 0%, rgba(255,255,255,0) 100%); 
    position: absolute;
    padding-top: 500px;
    padding-left: 100vw;

}

.orgName {
    font-family: Kanit;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #0182FA
}

.slogan {
    font-family: Kanit;
    font-size: 72px;
    font-weight: 700;
    line-height: 86px;
    letter-spacing: 0em;
    text-align: left;
    color: #032B42;
}

.text {
    font-family: Kanit;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
}

.plane {
    width: 83px;
    height: 83px;
    top: 31px;
    right: calc(58.3vw - 222.24px);
    transform: rotate(-14deg);
    position: absolute;
    animation: plane 4s forwards, animatePlane 2s 5s infinite forwards;
    z-index: 2;
}

@keyframes plane {
    0% {right: calc(100vw - 8px); top: 228px;}
    100% {right: calc(58.3vw - 222.24px); top: 51px;}
}

@keyframes animatePlane {
    0% { transform: rotate(-14deg); }
    50% { transform: rotate(-5deg); }
    100% { transform: rotate(-14deg); }
}

.car {
    width:61px;
    height: 61px;
    top: 220px;
    left: 356px;
    transform: scaleX(-1);
    position: absolute;
    animation: car 4s forwards, animateCar 2s 5s infinite forwards;
    z-index: 1;
}

@keyframes car {
    0% {left: 1000px;}
    100% {left: 356px;}
}

@keyframes animateCar {
    0% { top: 220px; }
    50% { top: 225px; }
    100% { top: 220px; }
}

.boat {
    width: 58px;
    height: 58px;
    top: 470px;
    left: 450px;
    position: absolute;
    transform: rotate(-5deg);
    animation: animateBoat 2s infinite forwards;
    z-index: 1;
}

@media (900px <= width < 1100px) {
    .boat {
        display: none;
    }
}

@keyframes animateBoat {
    0% { transform: rotate(-5deg); }
    50% { transform: rotate(5deg); }
    100% { transform: rotate(-5deg); }
}

.macBookImg {
    width: 919px; 
    height: 476px;
    object-fit: fill; 
    float: left;
}

.slideImg {
    width: 715px;
}

@media (600px <= width < 900px) {
    .macBookImg {
        width: 77.4%;
        height: calc((77.4vw - 84px) * 0.518);
        margin: auto;
        float: none;
    }
    .slideImg {
        width: calc((77.4vw - 84px) * 0.778);
    }
    .slogan{
        margin-top: 80px;
        text-align: center;
    }
    .connect {
        position: absolute;
        top: 124px;
        left: calc(50vw - 1010px);
        z-index: -1; 
    }
    .boatMobile {
        width: 60px;
        height: 60px;
        right: calc(50vw - 290px);
        top: 314px;
        position: absolute;
        transform: rotate(-5deg);
        animation: animateBoat 2s infinite forwards;
        z-index: 1;
    } 
    .plane {
        left: calc(50vw - 250px);
        top: 22px;
        width: 83px;
        height: 83px;
        animation: animatePlane 2s 5s infinite forwards;
    }  

    .text {
        font-size: 20px;
    }
}

.arrowDown {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    animation: hover 3s infinite forwards;
}

@keyframes hover {
    0% { bottom: 10px; }
    50% { bottom: 15px; }
    100% { bottom: 10px; }
}

/* mobile view */
@media (width < 600px) {
    .plane {
        left: calc(50vw - 169.5px);
        top: 22px;
        width: 65px;
        height: 65px;
        animation: animatePlane 2s 5s infinite forwards;
    }   
    .boatMobile {
        width: 45px;
        height: 45px;
        right: calc(50vw - 150px);
        top: 200px;
        position: absolute;
        transform: rotate(-5deg);
        animation: animateBoat 2s infinite forwards;
        z-index: 1;
    } 
    .macBookImg {
        width: 100%;
        height: calc((100vw - 92px) * 0.518);
    }
    .slideImg {
        width: calc((100vw - 92px) * 0.778);
    }
    .slogan {
        font-size: 40px;
        line-height: 52px
    }
    .text{
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }
    .connect {
        position: absolute;
        top: 86px;
        left: calc(50vw - 1010px);
        z-index: -1; 
        height: 108px;
    }
}