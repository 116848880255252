.title {
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(1rem, 7vw, 32px);
    line-height: 72px;

    color: #194B7E;

    margin-top: 30px !important;
    margin-bottom: 30px;

}

.notFound{
    font-family: Kanit;
    font-size: 30px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    color: #515151;
    margin-top:50px;
    margin-bottom: 50px;
}

.option{
    font-family: Kanit;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #515151;
}
.optionHeader{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #194B7E;
    margin-bottom: 5px;
    margin-left: 7px;

}

.underline{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #0182FA;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 10px;
}

.reset{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #0182FA;
    text-decoration: underline;
    display: flex;
    cursor: pointer;
    align-items:center; 
    margin-right: 10px;
    transition: 0.5s;
}

.sort{
    display: flex;
    align-items:center;

    font-family: Kanit;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #515151;
}