.title {
    font-family: Kanit;
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: center;
    color: #032B42;
}

.img {
    height: 124.36px;
    border-radius: 10px;
    margin-bottom: 25px;
    width: 100%;
    object-fit: cover;
    transition: box-shadow 0.3s;
}

.category:hover > img {
    box-shadow: 2px 19px 21px -8px rgba(0, 0, 0, 0.25);

}

.description{
    font-family: Kanit;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;

}

.slideIn {
    transform: translateY(0) !important;
    opacity: 1 !important;
}

.hideBox {
    font-family: Kanit;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: center;
    color: #032B42;

    transform: translateY(50%);
    opacity: 0;
    transition: opacity 2s ease, transform 1s ease;

}

@media (width < 600px) {
    .title {
        font-size: 28px;
    }
    .description {
        font-size: 12px;
        line-height: 20px;
    }
    .hideBox {
        font-size: 16px;
    }
    .img {
        margin-bottom: 17px;
    }
}