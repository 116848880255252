.button{
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.7rem;
    gap: 8px;


    background: #FFFFFF;
    border: 1.5px solid #0182FA;
    border-radius: 50px;

    cursor: pointer;

    font-family: Kanit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 1rem */
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;

    /* primary */

    color: #007DFA;

    width: -webkit-fill-available;

    transition: 0.2s;
    
    margin-bottom: 0.8rem;

}

.button:hover{
    background: #007DFA;
    color: #FFFFFF;
}

.textButton{
    margin-left: 10px;
    display: inline-block;
    vertical-align: super;
}

@media (width < 600px) {
    .button{
        margin-bottom: 1rem;
        font-size: 0.75rem;
        padding: 0.75rem;
    }

    .textButton{
        margin-left:0;
    }
}
.closeButton{

    position: absolute;
    right: 10px;
    top: 10px;

    cursor: pointer;
}