.title{
    font-family: Kanit;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #194B7E;
    margin-top: 45px;
}

.subtitle{
    font-family: Kanit;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    color: #3A3A3C;
    margin-bottom: 35px

}

.header{
    display: inline-block; 
    margin-right: 20px;
    font-family: Kanit;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #194B7E;

}

.label{

    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    margin: 20px 0;

}


@media(width < 600px){
    .title{
        font-size: 24px;
    }
    .subtitle{
        font-size: 16px;
    }
    .label{
        font-size: 14px;
        margin: 15px 0;
    }
}