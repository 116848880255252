.text1{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 30px;
    text-align: center;
    /* margin: 10px 0px ; */
    color: #194B7E;
}

.text2{
    font-family: Kanit;
    font-style: normal;   
    font-weight: 300;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    color: #515151;

}

.wrapper{
    padding-top: 50px;
    padding-bottom: 50px;
}