.header{
    color: #194B7E;
    text-align: center;
    font-family: Kanit;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
}

.subheaderCard{
    color: #3A3A3C;
    text-align: center;
    font-family: Kanit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
}

.subheader{
    color: #3A3A3C;
    font-family: Kanit;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;

}

.headerWrapper{
    margin-top: 35px;
}

.text{
    color: var(--dark-dark-1, #3A3A3C);
    font-family: Kanit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    
}

.textBold{
    color: var(--dark-dark-1, #3A3A3C);
    text-align: center;
    font-family: Kanit;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 

    margin-top: 3.75rem;
}

.reminder{
    margin-top:10px;

    font-family: Kanit;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #3A3A3C;
}

@media(width < 600px){
    .header{
        font-size: 24px;
    }
    .subheader{
        font-size: 16px;
    }
    .subheaderCard{
        font-size: 14px;
    }
    .textBold{
        font-size: 16px;
    }
}