.copyright{
    margin-top:20px;
    font-family: Kanit;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
}

.wrapper{
    padding-top: 44px; 
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    background-color: #092C50;
    text-align: left;
}