.textHeader{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    /* identical to box height */

    text-align: left;

    color: #194B7E;

    margin-bottom: 30px;

}

.text{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 15px;
    line-height: 22px;
    text-align: left;

    color: #000000;

}

.button{
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 8px;


    background: #FFFFFF;
    border: 1.5px solid #0182FA;
    border-radius: 8px;

    cursor: pointer;

    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;

    /* primary */

    color: #007DFA;

    width: -webkit-fill-available;

    transition: 0.2s;

}

.button:hover{
    background: #007DFA;
    color: #FFFFFF;
}

.input{
    width: 100% !important;
    margin-bottom: 30px !important;
    left:0;
    right: 0;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 1rem !important;

    font-family: Kanit !important;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
}

.label{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 15px;
    line-height: 100%;
    text-align: left;
    /* identical to box height, or 15px */


    color: #000000;
    display: block;
    margin-bottom: 5px;

}

.lock{
    height: 20px;
    object-fit: contain;
    display: table-caption;
    width: 20px;
    margin-top: -5px;
}

.forgetPassword{
    float: right;
    display: flex;
}

.forgetPassword:hover > .lock{
    filter:invert(56%) sepia(92%) saturate(1321%) hue-rotate(184deg) brightness(101%) contrast(101%);
}

.highlight{
    color: #0182FA;
}

.highlight:hover{
    color: #52ACFF;
}

.sign{
    object-fit: contain;
    width: 70px;
    height: auto;
    margin-right: 30px;
}