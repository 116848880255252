.title {
    font-family: Kanit;
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: center;
    color: #032B42;
}

.description {
    font-family: Kanit;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;

}

.prev{
    position: absolute;
    top: calc((100% - 95px) / 2);
    left: calc(4.914881933003848vw + 54.05958264689731px);
    cursor: pointer;
    z-index: 2;

    padding: 8px 9px 4px 7px;
    border-radius: 50px;
    background-color: #CBCBCB;

    opacity: 0.3;
    transition: opacity 0.3s;
}

.next{
    position: absolute;
    top: calc((100% - 95px) / 2);
    right: calc(4.914881933003848vw + 34.05958264689731px);
    cursor: pointer;
    z-index: 2;

    padding: 8px 7px 4px 9px;
    border-radius: 50px;
    background-color: #CBCBCB;

    opacity: 0.3;
    transition: opacity 0.3s;
}

.hoverToShow:hover > .prev{
    opacity:0.5;
}

.hoverToShowRight:hover > .next {
    opacity:0.5;
}

.prev:hover, .next:hover {
    opacity: 1 !important;
}

.seeMore{
    font-family: Kanit;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    vertical-align: middle;
}

@media (width < 600px) {
    .title {
        font-size: 28px;
    }
    .description {
        font-size: 12px;
        line-height: 20px;
    }

    .next, .prev{
        opacity: 1;
    }
    .next{
        right: 120px;
    }
    .prev{
        left: 120px;
    }
}

@media  (600px <= width < 900px){
    .next{
        right: 120px;
    }
    .prev{
        left: 120px;
    }
}

@media(1536px <= width < 1850px){
    .next{
        right: max(calc(1059.42194px - 60.538396728971954vw), 20px);
    }
    .prev {
        left:  max(calc(1059.42194px - 60.538396728971954vw), 20px);
    }
}

@media(width > 1850px){
    .next{
        right: -50px;
        opacity: 1;
    }
    .prev {
        left: -50px;
        opacity: 1;
    }
}