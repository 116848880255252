.title {
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(1rem, 7vw, 32px);
    line-height: 72px;

    color: #194B7E;

    margin-top: 30px !important;
    margin-bottom: 30px;

}

.notFound{
    font-family: Kanit;
    font-size: 30px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    color: #515151;
    margin-top:50px;
    margin-bottom: 50px;
}