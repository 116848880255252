.header{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #515151;
    margin-right: 10px;
}

.reset{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #0182FA;
    text-decoration: underline;
    display: flex;
    cursor: pointer;
    align-items:center; 
    transition: 0.5s;
}

.underline{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #0182FA;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 10px;
}

.option{
    font-family: Kanit;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #515151;
}
.optionHeader{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #194B7E;
    margin-bottom: 5px;
    margin-left: 7px;

}

.visible {
    opacity: 1;
}

.hidden {
    opacity: 0;
    pointer-events: none; /* Optionally disable interactions when hidden */
}

.sort{
    display: flex;
    align-items:center;

    font-family: Kanit;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #515151;
}

.filterCounts{
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
}
.filterCountsCircle{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #0182FA;
    background-color: rgba(1, 130, 250, 0.3);
    padding: 0 14px;
    border-radius: 50px;
    position: relative;
    margin-left: 10px;
}