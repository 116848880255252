sup{
   font-size: 9px;
}

.categoryBox{
    border-radius: 0 50px 50px 0;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0 10px;
    border-left: none;
}

.tag{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 4px 16px;
    border-radius: 50px;
    transition: background-color 0.3s;

    cursor: pointer
}

.tag:hover{
    background-color: #E6E7E8;
}

.disabled{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 4px 16px;
    border-radius: 50px;
    transition: 0.3s;
    color: rgba(0, 0, 0, 0.23);
    cursor: default;

}

.active{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 4px 16px;
    border-radius: 50px;
    color: white;
    background-color: #0182FA;
    cursor: pointer
}