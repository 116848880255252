.title{
    color: #032B42;
    text-align: center;
    font-family: Kanit;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.header{
    color: var(--Colors-color-blue-mid, #194B7E);
    font-family: Kanit;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 114.286% */
    text-align: left;
    margin-left: 20px;
}

.slideIn {
    transform: translateY(0) !important;
    opacity: 1 !important;
}

.hideBox {
    transform: translateY(50%);
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;

}

@media (width < 900px) {
    .header {
        font-size: 20px;
        line-height: 28px;
    }
    .title {
        font-size: 24px;
    }
}