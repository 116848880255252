.title{
    font-family: Kanit;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    color: #194B7E;
}

.closeButton{

    position: absolute;
    right: 10px;
    top: 10px;

    cursor: pointer;
}