.home{
    background-image: url("../../assets/home/Welcome.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    z-index: -2;
    overflow-x: hidden;
}
.ahref {
    color: #0182FA;
    cursor: pointer;
}