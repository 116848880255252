.tag{

    border-radius: 50px;
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-right: 10px;
    padding: 0 16px;
    white-space: nowrap;
}
.tag:last-child{
    margin-right: 0;
}