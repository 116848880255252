
.header{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    /* identical to box height */


    color: #FFFFFF;
}

.content{
    font-family: Kanit;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #E6E6E6;
}

.nobull{
    list-style: none;
    padding-left: 0;
}

a.link{
    color: #E5E5E5;
}

a.link:hover{
    color: #E5E5E5;
    text-decoration: underline;
}

.link {
    color: white;
    margin-bottom: 10px;
}
.linktext {
    color: white !important;
}

.linktext:hover {
    cursor: pointer;
    text-decoration: underline !important;
}
