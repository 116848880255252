.galleryCode{
    font-family: Kanit;
    font-size: 10px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 8px;
}

.pageTitle{
    font-family: Kanit;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #0182FA !important;
    text-decoration: underline !important;
    cursor: pointer; 
    flex: 1;   
}