.tag{

    border-radius: 50px !important;
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-right: 10px;
    padding: 0 16px;
    white-space: nowrap;
    width: 100px;
    height:24px !important;
}
.tag:last-child{
    margin-right: 0;
}

@media (width >= 1200px) {
    .dashboardBox{
        height: 633.938px;
    }
  }
@media (992px <= width < 1200px) {
    .dashboardBox{
        height: 525px;
    }
}
@media (768px <= width < 992px) {
    .dashboardBox{
        height: 407px;
    }
}
@media (width < 768px) {
    .dashboardBox{
        height: 52vw;
    }
}