.title {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 52px;
    text-align: left;

    color: #194B7E;

    margin: 50px 0;
}

.text {
    font-family: Kanit;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: left;

    color: #000000;
}



.MuiInputBase-input{
    padding: 0px !important;
}


.text_require {

    color: rgb(148, 33, 33);
}


.form_text {
    font-family: Kanit;
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 30px;

    color: #194B7E !important;
}

.placeholder_text {
    font-family: Kanit !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px !important;
    line-height: 27px !important;

}

.question_box {
    font-family: Kanit !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px !important;
    line-height: 27px !important;
    height: 100px !important;
}

.line_invitation_text{

    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    text-align: center;

    color: #194B7E;
}

.line_img{
    max-width: 139px;
    object-fit: contain;

}

.line_wrapper{
    margin:20px 0 !important;
    justify-content: space-around;
}

.input{
    width: 100%;
    margin-bottom: 30px !important;
    left:0;
    right: 0;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 1rem !important;

    font-family: Kanit !important;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
}