.tag{

    border-radius: 50px;
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding: 0 16px;
    white-space: nowrap;
    cursor: pointer;
}

.tinyTag{
    border-radius: 50px;
    font-family: Kanit;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding: 0 16px;
    white-space: nowrap;
    cursor: pointer;
}


.lean {
    padding: 0 8px;
}