.categoryHeader{
    font-family: Kanit;
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color: #194B7E;
    margin-right: 25px;
}

.categorySeemore{
    font-family: Kanit;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: right;
    color: #A6A8AB;
    margin-bottom: 4px;
    cursor: pointer;
}

.prev{
    position: absolute;
    top: 50%;
    left: 0;
    cursor: pointer;
}

.next{
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
}