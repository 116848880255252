.header {
    font-family: Kanit;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #194B7E;
    margin-bottom: 10px;
}

.closeButton {
    position: absolute;
    right: 16px;
    top: 16px;

    cursor: pointer;
}