.title{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 108px;
    /* identical to box height */

    text-align: left;

    color: #194B7E;

    padding-top: 30px;
}

.wrapper{
    padding-bottom: 50px;
}