.title{
    font-family: Kanit;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
}
.text1{
    font-family: Kanit;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #54595E;
}
.text2{
    font-family: Kanit;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #0182FA;

}
.img{
    height:47px;
    width:47px;
    margin:20px auto 17px;
    object-fit: contain;
}
.textbox{
    margin-bottom:20px;
}