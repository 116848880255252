.imgLogo{
    display: block;
    margin-left: auto;
    margin-right: 10px;
    height: 30px;
    width: 175px !important;
    object-fit: contain;
}

.rightMenu{
    display: flex;
    align-items: center;
    margin-right: 5px;
}

