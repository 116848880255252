.header{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 52px;
    text-align: left;

    color: #194B7E;

    margin: 50px 0;

}

.topic{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: left;
    padding: 20px !important;

    color: #032B42;
}

.title{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: left;
    padding: 20px !important;

    color: #032B42;
}

.img {
    object-fit: contain;
    max-height: 70vh;
    object-position: 0% 50%;
}

.img2{
    object-fit: contain;
    max-height: 70vh;
    object-position: 0% 50%;
}

@media (width < 600px){
    .img2{
        margin-left: -50px;
        width: -webkit-fill-available;
    }
}

.content{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
    padding-left: 30px !important;

    color: #032B42;
}

.envelop{
    background: #FAFAFA;
    box-shadow: 0px 2px 9px 4px rgba(202, 202, 202, 0.2);
    border-radius: 10px;
}

