.thankyoubg{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 1164px;
    background-color: #092C50;
}

.close_btn_white{
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 30px;
    cursor: pointer;
    color: #FFFFFF;
}

.tytext1{
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
    text-align: center;

    color: #FFFFFF;

    margin-top: 30px;
    margin-bottom: 10px;
}

.tytext2{
    font-family: Kanit;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    margin: 0px;

    color: #FFFFFF;
}

.line_invitation_text{

    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    text-align: center !important;

    color: #FFFFFF;
}

@media(width < 900px){
    .tytext1{
        font-size: 40px;
        line-height: 50px;
    }
    .tytext2{
        font-size: 25px;
        line-height: 35px;
    }
    .line_invitation_text{
        font-size: 14px;
    }
}

@media(width < 600px){
    .tytext1{
        font-size: 30px;
        line-height: 40px;
    }
    .tytext2{
        font-size: 16px;
        line-height: 20px;
    }
    .line_invitation_text{
        font-size: 12px;
    }
}



.line_img{
    max-width: 139px;
    object-fit: contain;

}

.line_wrapper{
    margin:15px 0 !important;
    justify-content: center;
}

.image{
    padding: 0 30px;
    min-width: 260px;
    max-width: 450px;
}