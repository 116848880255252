.primary1 {
    font-family: 'Kanit' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px ;
    line-height: 30px;
    color: #FFFFFF !important;
    background: #0182FA !important;


    font-family: 'Kanit';
    font-style: normal;


    color: #FFFFFF;
}

.primary1:hover {
    background: #52ACFF !important;
}


.secondary2 {
    font-family: 'Kanit' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px;
    line-height: 30px;

    color: #FFFFFF !important;
    background: #194B7E !important;
    border: 2px solid #194B7E !important;
    border-color: #194B7E !important;

    font-family: 'Kanit';
    font-style: normal;

    font-size: 20px;
    line-height: 30px;

}

.secondary2:hover {
    color: #FFFFFF !important;
    background: #416486 !important;
}

.tertiary {
    font-family: 'Kanit' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px;
    line-height: 30px;
    color: #0182FA !important;
    background: #FFFFFF !important;
    border: 2px solid #0182FA !important;
    border-color: #0182FA !important;


    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;

}

.tertiary:hover {
    color: #52ACFF !important;
    border-color: #52ACFF !important;

}