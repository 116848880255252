.header{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    text-align: center;

    color: #194B7E;

    margin: 50px 0;
}

.subheader{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 54px;
    text-align: left;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: #194B7E;

    margin-bottom: 20px;
    margin-top: 50px;

}

.text{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;

    color: #032B42;
    text-align: left;
}

.title{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height */

    text-align: center;

    color: #032B42;
}

.subtitle{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    color: #032B42;
}

.wrapper{
    margin-bottom: 100px;
}

.ahref{
    font-family: Kanit;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.17px;
    text-align: left;
    color: #0182FA;
    cursor: pointer;
}
