.title{
    color: var(--Colors-color-blue-dark, #032B42);
    text-align: center;
    font-family: Kanit;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 110px;
}

.selectionBox{
    border-radius: 50px;
    border: 1px solid var(--Colors-color-grey-light, #CBCBCB);
    background: var(--Button-label-primary-df, #FFF);
    width: fit-content;
    padding: 9px 10px;
}

.tag{
    display: inline-flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    color: var(--Colors-color-black, #000);
    text-align: center;
    font-family: Kanit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
    margin-right: 10px;
    cursor:pointer;
}

.tag:last-child{
    margin-right: 0;
}

.tag:hover{
    background-color: #E6E7E8;
}

.activeTag{
    background: #0182FA !important;
    color: white;
}

.description{
    font-family: Kanit;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;

}

.prev{
    position: absolute;
    top: calc((100% - 95px) / 2);
    left: 120px;
    cursor: pointer;
    z-index: 2;

    padding: 8px 9px 4px 7px;
    border-radius: 50px;
    background-color: #CBCBCB;

    opacity: 0.3;
    transition: opacity 0.3s;
}

.next{
    position: absolute;
    top: calc((100% - 95px) / 2);
    right: 120px;
    cursor: pointer;
    z-index: 2;

    padding: 8px 7px 4px 9px;
    border-radius: 50px;
    background-color: #CBCBCB;

    opacity: 0.3;
    transition: opacity 0.3s;
}

.hoverToShow:hover > .prev{
    opacity:0.5;
}

.hoverToShowRight:hover > .next {
    opacity:0.5;
}

.prev:hover, .next:hover {
    opacity: 1 !important;
}

@media (width < 600px) {
    .title {
        font-size: 28px;
    }
    .tag {
        font-size: 14px;
    }
    .description {
        font-size: 12px;
        line-height: 20px;
    }
    .next, .prev{
        opacity: 1;
    }
    
}

@media(1536px <= width < 1900px){
    .next{
        right: 20px;
    }
    .prev {
        left: 20px;
    }
}

@media(width > 1850px){
    .next{
        right: -50px;
        opacity: 1;
    }
    .prev {
        left: -50px;
        opacity: 1;
    }
}
