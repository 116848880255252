.title{
    font-family: Kanit;
    font-size: 25px;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: left;
    color: #164575;
}

.tag{

    border-radius: 50px !important;
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-right: 10px;
    padding: 0 16px;
    white-space: nowrap;
}
.tag:last-child{
    margin-right: 0;
}

@media (width >= 1215px) {
    .infoBox{
        height: 831px;
    }
  }
@media (900px <= width < 1215px) {
    .infoBox{
        height: calc(75vw - 80.25px);
    }
}
@media (width < 900px){
    .infoBox{
        height: calc(100vw - 99px);
    }
}

.detailHeader{
    font-family: Kanit;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #194B7E;
}

.header{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.text{
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #515151;
}

.relatedHeader{
    font-family: Kanit;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #194B7E;
}