.title{
    font-family: Kanit;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #194B7E;
}

.closeButton{

    position: absolute;
    right: 10px;
    top: 10px;

    cursor: pointer;
}

.img{
    width: 42px;
    height:42px;
    object-fit: contain;
    margin-right:10px
}
.img:last-child{
    margin-right:0
}
.divider{
    font-family: Kanit;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;

}
