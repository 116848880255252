.content{
    font-family: Kanit;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #E6E6E6;
}

.header{
    color: #FFF;
    font-family: Kanit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.organization{
    color: #FFF;
    font-family: Kanit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.socialBackground{
    position: relative;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    margin-right: 5px;
    background-color: #164575;
    transition: 0.2s;
}

.socialBackground:hover{
    position: relative;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    margin-right: 5px;
    background-color: #FFFFFF;
}

.socialBackground:hover path{
    fill:#164575;
}

.socialBackground:hover img{
    fill:#164575;
}

.socialIcon{
    position: absolute;
    height: 20px;
    left: 0;
    right: 0;
    top: 0;
    bottom:0;
    margin: auto;

    object-fit: contain;


}

.socialWrapper{
    display: inline-flex;
}




